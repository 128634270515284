import * as React from 'react'
import { useEffect, useState } from 'react'

import styled from 'styled-components'

import Layout from 'layouts/main'

// COMPONENTS
import { ButtonLink, SecondaryButtonLink } from 'components/styled/button'

import { SCREEN } from 'styles/screens'
import LinkedIn from 'svgs/linkedin.svg'
import ArrowTopRightIcon from './../../svgs/arrow_top_right.svg'
import axios from 'axios'
import { config } from '../../config'
import { formatName, getEmbedUrlForVideo, getImageUrl } from '../../Utils'
import { DEFAULT_PROFILE_IMAGE_URL } from '../../data/dictionary'
import NotFoundPage from '../404'

const Container = styled.div`
  min-height: 100vh;
  padding-right: 100px;
  padding-left: 100px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1520px;

  .fs-25px {
    font-size: 25px !important;
  }

  .fw-bold {
    font-weight: bold;
  }

  .mr-10px {
    margin-right: 10px;
  }

  .mr-30px {
    margin-right: 30px;
  }

  .text-decoration-none {
    text-decoration: none;
  }

  button {
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;

    > svg {
      margin-left: calc(var(--spacing) * 3.5);
    }
  }

  a {
    font-weight: 500;

    > svg {
      margin-left: calc(var(--spacing) * 3.5);
    }
  }

  .info-icon {
    width: 24px;
    height: 24px;
    margin-left: 15px;
    filter: opacity(.5)
  }

  .mt-20px {
    margin-top: 20px;
  }

  img.author {
    margin-right: 20px;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    object-fit: cover;
  }

  .social {
    height: 20px;

    svg {
      margin-left: .5rem;

      path {
        transition: all 0.3s;
        fill: var(--primary);
      }

      &:hover {
        path {
          fill: var(--secondary);
        }
      }
    }
  }

  &.py-3rem {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .embed-responsive {
    padding-top: 56.25% !important;
    position: relative;
    display: block;
    width: 100%;
    padding: 0;

    .embed-responsive-item {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .mt-50px {
    margin-top: 50px;
  }

  .my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .mb-50px {
    margin-bottom: 50px;
  }

  .mb-20px {
    margin-bottom: 20px;
  }

  .lh-1 {
    line-height: 1;
  }

  .label {
    line-height: 1;
    margin-bottom: 16px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #c4c4c4;

    img {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }

  p:not(.label) {
    font-size: 19px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .mt-mobile-20px {
      margin-top: 20px;
    }

    .mb-50px {
      margin-bottom: 30px;
    }

    .my-50px {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .pl-0 {
      padding-left: 0;
      padding-right: 0;
    }

    .pr-0 {
      padding-left: 0;
      padding-right: 0;
    }

    .label {
      margin-bottom: 14px;
    }

    padding: 16px;
    .mt-50px {
      margin-top: 10px;
    }

    &.py-3rem {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
`

const ColumnHalf = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`
const ColumnSix = styled.div`
  max-width: 50%;
  flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const Column = styled.div`
  max-width: 100%;
  flex: 0 0 100%;
  padding-left: 15px;
  padding-right: 15px;
`

const ChallengeSubmitted = ({ id }) => {

  const [loading, setLoading] = useState(true)
  const [challenge, setChallenge] = useState({
    data: {
      category: {},
      target_model_1: { value: '' },
      target_model_2: { value: '' },
      steps: [],
    },
  })
  const [hasError, setHasError] = useState(false)

  useEffect(async () => {
    try {
      let response = (await axios.get(`${config.API_BASE_URL}/challenges/${id}`)).data
      setChallenge(response)
    } catch (e) {
      setHasError(true)
    }
  }, [])

  if (hasError) {
    return <Layout title={`BuildUp: Form`} hideTitlePrefix={true} showFooter={true}>
      <Container className='py-3rem'>
        <NotFoundPage />
      </Container>
    </Layout>
  }

  const { data } = challenge

  return (
    <Layout title={`BuildUp: Form`} hideTitlePrefix={true}>
      <Container className='py-3rem'>
        <Row>
          <ColumnHalf className='mx-auto'>
            <div className='d-flex align-items-center'>
              <p className='label'>Title</p>
            </div>
            <p className='mt-0 mb-50px fs-25px fw-bold'>{data.title}</p>
            <div className='d-flex align-items-center'>
              <p className='label'>Category</p>
            </div>
            <p className='mt-0 mb-50px'>{data.category.label}</p>
            <div className='d-flex align-items-center'>
              <p className='label'>Target Model</p>
            </div>
            <p className='mt-0 mb-50px'>{`${data.target_model_1.value} ${data.target_model_2.value}`.trim()}</p>


            <div className='d-flex align-items-center'>
              <p className='label'>Key Stakeholders</p>
            </div>
            <p className='mt-0 mb-50px'>{data.stakeholders}</p>
          </ColumnHalf>
          <ColumnHalf className='mx-auto'>
            {challenge.mentor &&
            <div className='d-flex flex-column px-0'>
              <div className='d-flex'>
                <img
                  src={getImageUrl(challenge.mentor.image, DEFAULT_PROFILE_IMAGE_URL)}
                  className='author' alt='' />
                <div className='my-mobile-10px'>
                  <p className='title mb-0 mt-0 lh-1'>{formatName(challenge.mentor)}</p>
                  <p className='text-muted mt-1 mb-0 lh-1 d-flex align-items-center'>mentor
                    {challenge.mentor.linkedin &&
                    <a href={challenge.mentor.linkedin} target='_blank' className='social'><LinkedIn /></a>
                    }
                  </p>
                </div>
              </div>
              <Row className='mt-20px w-100'>
                <ColumnSix className='pl-0'>
                  <ButtonLink className='w-100'
                              href={`/challenge-apply?id=${challenge.id}`}> Apply <ArrowTopRightIcon /></ButtonLink>
                </ColumnSix>
                {challenge.mentor.calendly &&
                <ColumnSix className='pr-0'>
                  <SecondaryButtonLink href={challenge.mentor.calendly} target='_blank'
                                       className='w-100 mt-mobile-20px'>Contact Mentor</SecondaryButtonLink>
                </ColumnSix>
                }
              </Row>


            </div>
            }
            {challenge.data.video_embed_url && !challenge.data.video_url &&
            <div className='embed-responsive my-50px'>
              <iframe
                width='100%'
                height='100%'
                src={getEmbedUrlForVideo(challenge.data.video_embed_url)}
                title='Vimeo video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                className='embed-responsive-item'
              />
            </div>
            }
            {challenge.data.video_url &&
            <div className='embed-responsive my-50px'>
              <video controls className='embed-responsive-item'>
                <source src={getImageUrl(challenge.data.video_url)} type='video/mp4' />
              </video>
            </div>
            }
          </ColumnHalf>
        </Row>
        <Row>
          <Column>
            <div className='d-flex align-items-center'>
              <p className='label'>High-Level Problem Summary</p>
            </div>
            <p className='mt-0 mb-50px'>{data.problem_summary}</p></Column>
        </Row>
        <Row>
          <ColumnHalf className='mx-auto'>
            <div className='d-flex align-items-center'>
              <p className='label'>What is the nature of the problem?</p>
            </div>
            <p className='mt-0 mb-50px'>{data.problem_nature}</p>

          </ColumnHalf>
          <ColumnHalf className='mx-auto'>
            <div className='d-flex align-items-center'>
              <p className='label'>Problem location</p>
            </div>
            <p className='mt-0 mb-50px'>{data.problem_location}</p>
          </ColumnHalf>
        </Row>
        <Row className='mb-20px'>
          <ColumnHalf className='mx-auto'>
            <div className='d-flex align-items-center'>
              <p className='label'>Steps in the process</p>
            </div>
          </ColumnHalf>
          <ColumnHalf className='mx-auto'>
          </ColumnHalf>
        </Row>
        {data.steps.map((step, index) => {
          return <Row key={`step-${index}`}>
            <ColumnHalf className='mx-auto'>
              <p className='label'>STEP {index + 1}</p>
              <p className='mt-0 mb-50px'>{step.step}</p>
            </ColumnHalf>
            <ColumnHalf className='mx-auto'>
              {step.pain && <>
                <p className='label'>STEP 2: Pain</p>
                <p className='mt-0 mb-50px'>{step.pain}</p>
              </>}
            </ColumnHalf>
          </Row>
        })}
      </Container>
    </Layout>
  )
}

export default ChallengeSubmitted
